<template>
  <TopBar />
  <div class="wrapper">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <div class="step-1" v-if="step == 1">
        <h1>Speluitleg</h1>
        <p class="text-center">
          Hoe meer loten je vangt,<br />
          hoe groter je kans op een prijs.
        </p>
        <Vue3Lottie
          class="lottieIntroduction"
          :width="450"
          :height="650"
          :animationData="lottieInstructions"
          :loop="true"
        />
        <div class="priority-card">
          <div class="image-wrapper">
            <img src="@/assets/images/hand.svg" />
          </div>
          <div class="content">
            <h2>Vang de loten</h2>
            <p>Sleep de trofee naar links en rechts om loten te vangen.</p>
          </div>
        </div>

        <button @click="step = 2" class="btn btn-primary">Verder</button>
      </div>

      <div class="step-2" v-else-if="step == 2">
        <h1>
          Speluitleg
          <img class="back" @click="step = 1" src="@/assets/images/arrow-left-circle.svg" />
        </h1>
        <p class="text-center">
          Kijk goed wat je<br />
          wel en niet wil vangen
        </p>
        <div
          class="priority-card animate__animated animate__faster animate__fadeInUp animate__delay-1s"
        >
          <div class="image-wrapper">
            <img src="@/assets/images/priority_lot.png" />
          </div>
          <div class="content">
            <h2>Priority lot</h2>
            <p>+250 punten</p>
          </div>
          <div class="check-wrapper">
            <img src="@/assets/images/checked.svg" />
          </div>
        </div>
        <div
          class="priority-card animate__animated animate__faster animate__fadeInUp animate__delay-2s"
        >
          <div class="image-wrapper">
            <img src="@/assets/images/coffee_element.png" />
          </div>
          <div class="content">
            <h2>Cafeïne shot</h2>
            <p>
              Het spel gaat<br />
              2x zo snel
            </p>
          </div>
          <div class="check-wrapper">
            <img src="@/assets/images/checked.svg" />
          </div>
        </div>
        <div
          class="priority-card animate__animated animate__faster animate__fadeInUp animate__delay-3s"
        >
          <div class="image-wrapper">
            <img src="@/assets/images/freeze_element.png" />
          </div>
          <div class="content">
            <h2>Ijsbakje</h2>
            <p>
              Het spel bevriest <br />
              3 seconden
            </p>
          </div>
          <div class="check-wrapper">
            <img src="@/assets/images/unchecked.svg" />
          </div>
        </div>

        <button @click="startGame()" :disabled="!canStartGame" class="btn btn-primary">
          Start je spel
        </button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import TopBar from '@/components/TopBar.vue'
</script>

<script>
import lottieInstructions from '@/assets/lottie/introduction-data.json'

export default {
  data() {
    return {
      lottieInstructions,
      step: 1,
      canStartGame: false
    }
  },
  created() {},
  mounted() {
    this.$store.dataLayerPush({
      event: 'instructions'
    })
  },
  computed: {},
  methods: {
    startGame() {
      this.$store.dataLayerPush({
        event: 'start_game'
      })
      this.$router.push({ name: 'game' })
    }
  },
  watch: {
    step(step) {
      if (step === 2) {
        setTimeout(() => {
          this.canStartGame = true
        }, 3500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lottieIntroduction {
  position: absolute;
  top: -77px;
  width: 80%;
  left: 10%;
  z-index: -1;
}

h1 {
  position: relative;
  margin-top: 35px;
  text-align: center;
  .back {
    position: absolute;
    left: 40px;
  }
}

.step-1 {
  .priority-card {
    margin-top: 245px;
  }
}

.step-2 {
  .priority-card {
    padding: 10px;
    z-index: -1;
  }
}

.check-wrapper {
  margin-left: auto;
  align-items: center;
  display: flex;
}

.btn-primary {
  max-width: 335px;
}
</style>
